import type { XccEnvironment } from '@xcc-client/services';

export const environment: XccEnvironment = {
  envMode: 'prod',
  isProduction: true,
  stripeApiPublicKey: 'pk_live_JsSn3uTYUcnAaofAdCOA37XF00C76PIHpq',
  xccApiHost: 'https://checkout-api.ecom-prod.aarpdriversafety.org',
  xgritApiHostUrl: 'https://prd.xgritapi.com/api/1',
  xgritAuthorization: 'Basic MmM5MjBlOGYxOTI0ZWVjNzgxZWRjMzE1ZjgxZTQzYTE6',
  xgritWebAppUrl: 'https://app.aarpdriversafety.org/user/signin',
  brand: 'aarp',
  domain: '.aarpdriversafety.org',
  clientHost: 'https://www.aarpdriversafety.org/',
  termsAndConditionsUrl: 'https://www.aarp.com/terms-and-conditions/',
  recaptchaSiteKey: '6LcnfWMjAAAAAI7wMlWYTqnWRTivwx-bmDsmIzQL',
  stripeBetas: 'elements_enable_deferred_intent_beta_1',
  stripeApiVersion: '""',
  addPurchaseQueryParam: true,
  ambassador: {
    uid: '',
    isRecordConversionEnabled: true,
  },
  sentryDsn: 'https://03b57d83a9f07fed1c7f72a840335a22@o1007654.ingest.sentry.io/4506390228172800',
  passwordConfig: {
    passwordRegEx: '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{9,}$',
    passwordNotMatchMessage: 'Must be at least 9 characters with 1 uppercase letter, 1 lowercase letter, and 1 number.',
    showPasswordStrength: true,
  },
  temporaryDownurl: 'course-temporarily-unavailable/',
  adobeAnalytics: 'ENfc31ab1bac944ca8866743454782e0b0',
};
